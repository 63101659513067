import React from 'react';
import {Link} from "react-router-dom";
import './About.css';



export function About(props){
    document.body.style = "background-color: " + props.bgco + ';'
    document.getElementById("favicon").href = "/abouticon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
    return (
        <div>
            <Link className="backHome" to="/" 

                    onMouseEnter={() => {document.body.style = "background-color: rgb(134, 196, 186);"
                    document.getElementById("favicon").href = "/favicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}
                    onMouseLeave={() => {document.body.style = "background-color: " + props.bgco + ';'
                    document.getElementById("favicon").href = "/abouticon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}>
                <i class="homeButton fas fa-walking fa-3x fa-flip-horizontal" ></i>
            </Link>
    <div className="pageOuterContainer">
        <h1 className="pageH1">About</h1>
        <br />
        <p className="pageP">Hi, I'm Jack. I'm a fourth-year CS+Math student at WashU in St. Louis. I enjoy developing across the stack, building apps that scale, and exploring machine learning.</p>
        <br />
        <p className="aboutP">At about 3 AM one rainy November night a couple of years ago, sitting in the library, squashing bugs in my web-development assignment, I made the realization — <strong>everything</strong> is a learning opportunity. These bugs were opportunities to learn PHP better. The fact that it was 3 AM was an opportunity to learn to stop procrastinating. This was transformational for me, and now I bring this mindset everywhere I go. </p> 
        <br></br>
        <p className="aboutP">Since then, I learned that taking classes and succeeding in internships remotely can work, how to cook more exciting food than Easy Mac, and an astonishing amount about the effectiveness of traffic signal pre-emption systems. (Seriously. I wrote a 70-page paper on how well this could work in the neighborhood of Dorchester, Boston.) Anyways, if you're on this page, you're probably most interested in my coding work, so feel free to check out my projects on the  <Link 
                                    to="/code" 
                                    className="toCodeLink"
                                    onMouseEnter={() => {document.body.style = "background-color: #a04eed;"
                                    document.getElementById("favicon").href = "/codicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}
                                    onMouseLeave={() => {document.body.style = "background-color: " + props.bgco + ';'
                                    document.getElementById("favicon").href = "/abouticon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}
                                >Code</Link> page.</p>
        <br></br>
        <p className="aboutP">{"My current languages of choice are JavaScript (especially TypeScript!) and Python, and I've also spent plenty of time with Java, PHP and MatLab. I've worked with a variety of technologies along my journey, including Node.js, React.js, TensorFlow, Docker, Jenkins, TailwindCSS, and Git/GitHub. I've worked at Capital One twice and a St. Louis-based start-up named SimulPass, and I recently began a full-year co-op at Lantern in San Francisco."}</p>
       
        <br />
        <p className="aboutP">Send me an email at <a href="mailto:me@jackhoran.dev" className="email">me@jackhoran.dev</a> if you'd like to get in touch - I'm happy to talk!</p>
        <br />
        <br />
    </div></div>
    )
}


