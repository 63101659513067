import React from 'react';
import {Link} from "react-router-dom";
import './design.css';
import { useSpring, animated } from 'react-spring'

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

function DesignImage(p){
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 30, tension: 120, friction: 300 } }))

    return (
        <div >
            <animated.div
                onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                style={{ transform: props.xys.interpolate(trans) }} 
            >
                <img alt={p.alt} src={p.src} className="designImage" width="100%" height="auto"/>
            </animated.div>
        </div>
    )
}

export function Design(props){
    document.body.style = "background-color: " + props.bgco + ';'
    document.getElementById("favicon").href = "/designicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
    return (
        <div>
            <Link className="backHome" to="/" 
                    onMouseEnter={() => {document.body.style = "background-color: rgb(134, 196, 186);"
                    document.getElementById("favicon").href = "/favicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}
                    onMouseLeave={() => {document.body.style = "background-color: " + props.bgco + ';'
                    document.getElementById("favicon").href = "/designicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}>
                <i class="homeButton fas fa-walking fa-3x fa-flip-horizontal" ></i>
            </Link>
        <div className="pageOuterContainer">
            <h1 className="pageH1">Design</h1>
            <br />
            <p className="aboutP">Graphic design is a hobby of mine - I've been doing freelance work intermittently for the past six years. In high-school, I learned Cinema 4D and Photoshop and found myself working for YouTube content creators. 
                            I believe this has helped me <strong>tremendously</strong> when doing UX/UI work - both in making my interfaces visually appealing and easy to use.
                            This page features a smorgasbord of my designs, from my freelancing roots to today. If you're also interested in my music, <Link 
                                    to="/music" 
                                    className="toMusicLink"
                                    onMouseEnter={() => {document.body.style = "background-color: rgb(237, 155, 78);"
                                    document.getElementById("favicon").href = "/musicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}
                                    onMouseLeave={() => {document.body.style = "background-color: " + props.bgco + ';'
                                    document.getElementById("favicon").href = "/designicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}
                                >click here</Link>.</p>
            <div className="designContainer">
                <DesignImage src="bykizah.jfif" alt="Youtube Channel Art"/>
                <DesignImage src="orangeball.png" alt="Cinema 4D Render"/>
                <DesignImage src="master.png" alt="Website - Master"/>
                <DesignImage src="solar.jfif" alt="Youtube Channel Art"/>
                <DesignImage src="greenball.png" alt="Cinema 4D Render"/>
                <DesignImage src="kyoto.jfif" alt="Youtube Channel Art"/>
                <p className="aboutP">Note - I didn't design Instagram Desktop, as you probably thought from seeing this. I set myself a challenge of replicating their UI with only HTML and CSS, and this is the result.</p>
                <DesignImage src="instaclone.png" alt="Website - Instagram Desktop Clone"/>
                <DesignImage src="taxy.jfif" alt="Youtube Channel Art"/>
                <p className="aboutP">Same here - I didn't create the WashU law homepage. However, I noticed it could use some work, so I took the content from the homepage and rebuilt it with HTML and CSS.</p>
                <DesignImage src="wulaw.webp" alt="Website - WU Law Remake"/>
                <br />
            </div>
            </div></div>

    )
}


