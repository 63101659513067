import React from 'react';
import {Link} from "react-router-dom";
import './Code.css';
import { useSpring, animated } from 'react-spring'

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const projects = [
    {
        title: "aimless",
        time: "Spring 2021",
        stack: "d3, JavaScript, Node.js",
        images: [
            {
                src: "/aimless1.png",
                alt: "aimless feature selector"
            },
            {
                src: "/aimless2.png",
                alt: "aimless playlist generator"
            }
        ],
        description: "https://aimless.glitch.me is a project I'm VERY excited about. This site I built has generated over 10,000 song suggestions for 400 users in 20 countries. If you have a Spotify account, please try it out! " +
        "Essentially, you put in an existing playlist that you or someone else has created and choose your favorite songs and artists from it. aimless will then show you properties of your playlist in a graph. and you will choose " +
        "features you like based on them (you get control over how energetic the playlist you want will be, etc). Finally, aimless will generate a playlist for you based on your preferences and you get the option to add it to your Spotify account. " +
        "I LOVE music, so building this and testing it was SO MUCH FUN!!"
    },
    {
        title: "ArchHacks",
        time: "Spring 2021 — Present",
        stack: "GraphQL, React, Bootstrap",
        images: [
            {
                src: "/archhacks1.png",
                alt: "ArchHacks Homepage"
            },
            {
                src: "/archhacks2.png",
                alt: "ArchHacks Day-Of Dashboard Sneak-Peek"
            }
        ],
        description: "I am the ArchHacks tech lead for the upcoming Spring 2022 e-hackathon, expecting over 200 virtual attendees! I'm working with eight other developers and two designers to get our landing page, day-of dashboard, and submission portal up and running. The most recent in-person ArchHacks event saw over 3,000 applicants and $30,000 in prizes! \n " +
                    " \n My personal focus with this project is to guide the vision of the content we create, and I do so by planning sprints, giving pull-request feedback, and leading team meetings."
    },
    {
        title: "Crowdfunded",
        time: "Summer 2020 — Present",
        stack: "Python, TensorFlow",
        images: [
            {
                src: "/crowdfunded1.png",
                alt: "Crowdfunded Graph"
            },
            {
                src: "/crowdfunded2.png",
                alt: "Crowdfunded Results"
            }
        ],
        description: "Crowdfunded is a pet project I can't get my mind off. I'm building an algorithm to trade stocks automatically based on sentiment around the web. Twitter, Reddit, Google Trends & News — all of these platforms inherently rank content based on user sentiment (e.g. on Twitter, users can Like and Retweet tweets they like). " +
                    "I'm using publicly-accessible sentiment data from these platforms alongside technical indicators like relative volume and MACD to scan for stocks. Then, I throw all of this info into a statistical ML model I'm working on (based off the ES-RNN time series forecasting model that won the M4 forecasting competition in 2018). " +
                    "Currently, I've been manually entering trades (based off of the scanner portion of this project) in a small account, which has grown exponentially since I began in May. This is until I can finish creating the model, which I'll hook up to an algo-brokerage like Alpaca!"
    },
    {
        title: "The Moving Furniture Problem",
        time: "Spring 2020",
        stack: "Java, Swing",
        images: [
            {
                src: "/movingf1.png",
                alt: "Moving Furniture Start Position"
            },
            {
                src: "/movingf2.png",
                alt: "Moving Furniture End Position"
            },
        ],
        description: "The Moving Furniture Problem is a specific NP-hard problem a faculty advisor and I took some time to study. The premise is simple: if furniture in a room had wheels, how do we find the quickest path for all pieces of furniture to take between different room configurations (e.g. from conference to classroom)? " +
                    "The main problem we faced studying this problem was having a way to reproduce the results of various papers. I tackled this by building a simulation engine in Java with Swing. " +
                    "Complex heuristics search through all of space-time, so in addition to building a surface-level rendering of furniture movement, I built a suite of collision detection methods and multiple representations of spacetime " +
                    "to search in different ways (by object, location, etc.) with minimal time complexity. I also read an assortment of papers addressing this problem and worked out how their heuristics functioned so we could implement them in control-group scenarios I created. " +
                    "Once in-person activities return to normal, I hope to work with my advisor to develop a real-world simulation of these heuristics with miniature chairs and tables with wheels and motors controlled by a central computer."
    },
    {
        title: "Can't Hold'Em!",
        time: "Winter 2019-2020",
        stack: "React, MySQL, Node.js, Express, Socket.IO",
        images: [
            {
                src: "/poker1.png",
                alt: "Can't Hold'Em! Demo"
            }
        ],
        description: "Can't Hold'Em! is a full-stack web Texas Hold'em game I developed so my friends and I wouldn't have to pay for games on other sites when we play! I built out a number of features so the game plays quickly, smoothly, and safely. These include " +
                    "a chatroom, game histories, and user accounts! The entire game takes place in real-time on a single page, so users can focus on enjoying the game!"
    },
    {
        title: "The Atlantic Times",
        time: "Fall 2019",
        stack: "PHP, MySQL, Apache",
        images: [
            {
                src: "/atlantic1.png",
                alt: "Atlantic Times Demo"
            }
        ],
        description: "One of my first forays into full-stack web development, The Atlantic Times is a platform where contributors can post stories while ordinary users can react and reply to them. " +
                    "If facebook is a democracy and traditional news outlets are an autocracy, The Atlantic Times is a republic, giving certain selected users the ability to start conversations and set the tone " +
                    "for debate, while standard users still have the voice to reply to stories, other comments, and select between a number of reactions for stories and comments."
    }
]

function CodeImage(p){
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 1, tension: 30, friction: 10 } }))

    return (
        <div style={{marginBottom: "2vh"}}>
            <animated.div
                onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                style={{ transform: props.xys.interpolate(trans) }} 
            >
                <img alt={p.image.alt} src={p.image.src} className="projectImage" />
            </animated.div>
        </div>
    )
}

function Project(props){
    let imgs = []
    props.images.forEach(image => {
        imgs.push(<CodeImage image={image}/>)
    })
    return (
        <div>
            <p className="pageP">{props.title}</p>
            <br />
            <div className ="imgContainer">
                {imgs}
            </div>
            <br />
            <p className="timeAndTechP">{props.time} | <span className="techStackP">{props.stack}</span></p>
            <p className="aboutP">{props.description}</p>
            <br />
        </div>
    )

}

export function Code(props){
    document.body.style = "background-color: " + props.bgco
    document.getElementById("favicon").href = "/codicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
    let projs = []
    projects.forEach(project => {
        projs.push(
            <Project title={project.title} time={project.time} stack={project.stack} description={project.description} images={project.images}></Project>
        )
    })
    return (
        <div>
            <Link className="backHome" to="/" 
                    onMouseEnter={() => {document.body.style = "background-color: rgb(134, 196, 186);"
                    document.getElementById("favicon").href = "/favicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}
                    onMouseLeave={() => {document.body.style = "background-color: " + props.bgco + ';'
                    document.getElementById("favicon").href = "/codicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}>
                <i class="homeButton fas fa-walking fa-3x fa-flip-horizontal" ></i>
            </Link>
    <div className="pageOuterContainer">
        <h1 className="pageH1">Code</h1>
        <br />
        <p className="pageP">My full-stack experience at Lantern and Capital One and Android experience at SimulPass have helped me grow immensely — but here, I'd like to focus on past and present personal projects I'm most excited about.</p>
        <br />
        {projs}
        <br />
        <br />
        <p className="aboutP">If you're interested in seeing more that I've made, head over to my <Link 
                                    to="/design" 
                                    className="toDesignLink"
                                    onMouseEnter={() => {document.body.style = "background-color: #3e7a4e;"
                                    document.getElementById("favicon").href = "/designicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}
                                    onMouseLeave={() => {document.body.style = "background-color: " + props.bgco + ';'
                                    document.getElementById("favicon").href = "/codicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}
                                >Design</Link> page!</p>
        <br />
        <br />

    </div></div>
    )
}

export default Code