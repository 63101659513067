import React from 'react';
import {Link} from "react-router-dom";
import emailjs from 'emailjs-com';
import './design.css';

function sendEmail(e) {
    e.preventDefault();
    
    emailjs.sendForm('gmail', 'template_GfMEc1q9', e.target, 'user_8igtDDX3R1gLQjMmXJEcd')
      .then((result) => {
        let inputs = document.getElementsByClassName("contactFormTextInput")
        for(let i of inputs){
            i.value = ""
        }
        alert("Thank you for submitting!")
      }, (error) => {
          console.log(error.text);
      });
  }


export function Contact(props){
    document.body.style = "background-color: " + props.bgco + ';'
    document.getElementById("favicon").href = "/musicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
    return (
        <div>
            <Link className="backHome" to="/" 
                    onMouseEnter={() => {document.body.style = "background-color: rgb(134, 196, 186);"
                    document.getElementById("favicon").href = "/favicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}/* TODO: FAVICON CHANGE IN THESE LINKS */
                    onMouseLeave={() => {document.body.style = "background-color: " + props.bgco + ';'
                    document.getElementById("favicon").href = "/musicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}/* TODO: FAVICON CHANGE IN IN-PAGE LINKS */
            >
                <i id="contactHomeButton" class="homeButton fas fa-walking fa-3x fa-flip-horizontal" ></i>
            </Link>
            <div className="pageOuterContainer designOuterContainer">
                <h1 id="contactH1" className="pageH1">Contact</h1>
                <br />
                <p id="contactPageP" className="pageP">Feel free to reach out to me! I'll get back to you soon.</p>
                <br />
                <form className="contact-form" onSubmit={sendEmail}>

                    <label className="contactFormMember">Name</label>
                    <input className="contactFormMember contactFormInput contactFormTextInput" type="text" name="user_name" />
                    <label className="contactFormMember">Email</label>
                    <input className="contactFormMember contactFormInput contactFormTextInput" type="email" name="user_email" />
                    <label className="contactFormMember">Message</label>
                    <textarea className="contactFormMember contactFormInput contactFormTextInput" name="message" />
                    <input className="contactFormMember contactFormInput" type="submit" value="Send" />
                </form>
                <br />
            </div>
        </div>
    )
}


