import React from 'react';
import {Link} from "react-router-dom";
import './design.css';

export function Music(props){
    document.body.style = "background-color: " + props.bgco + ';'
    document.getElementById("favicon").href = "/musicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
    return (
        <div>
            <Link className="backHome" to="/" 
                    onMouseEnter={() => {document.body.style = "background-color: rgb(134, 196, 186);"
                    document.getElementById("favicon").href = "/favicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}/* TODO: FAVICON CHANGE IN THESE LINKS */
                    onMouseLeave={() => {document.body.style = "background-color: " + props.bgco + ';'
                    document.getElementById("favicon").href = "/musicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);}}/* TODO: FAVICON CHANGE IN IN-PAGE LINKS */
            >
                <i class="homeButton fas fa-walking fa-3x fa-flip-horizontal" ></i>
            </Link>
            <div className="pageOuterContainer">
                <h1 className="pageH1">Music</h1>
                <br />
                <p className="pageP">If I'm not writing code, I'm probably writing music.</p>
                <br />
                <p className="aboutP">When I'm not coding, designing, cooking, or Sudokuing, I'm probably making music. I'm minoring in it at school, and I have a passion for producing EDM and music theory. I can play piano, trombone, trumpet, and ukulele, and I'm currently learning bass guitar! Stay tuned for more music I'll release soon! :)</p>
                <br />
                <div className="musicContainer">
                    <a target="_blank" rel="noopener noreferrer" className="musicIconLink" href="https://open.spotify.com/artist/6U0oaXMLd11QWy7jbVZeVS?si=o77sBS5hQAO20o4U_eiE_w"><i class="musicIcon fab fa-spotify fa-4x"></i></a>
                    <a target="_blank" rel="noopener noreferrer" className="musicIconLink" href="https://music.apple.com/us/artist/bomp/364591208"><i class="musicIcon fab fa-itunes fa-4x"></i></a>
                    <a target="_blank" rel="noopener noreferrer" className="musicIconLink" href="https://soundcloud.com/thebomp"><i class="musicIcon fab fa-soundcloud fa-4x"></i></a>
                
                </div>
            </div>
        </div>
    )
}


