import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Card from './Card';
import { useState } from 'react';
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import {About} from './About.js'
import {Code} from './Code.js'
import {Design} from './Design.js'
import {Music} from './Music.js'
import {Contact} from './Contact.js'


function Wrapper(){
  const [hover, setHover] = useState(null)


  return(
    <div className="pageWrapper" style={getBGStyleOnHover(hover)}>
      <div className="cardWrapper">
        <Card hover={hover} setHover={props => setHover(props)} />
      </div>
    </div>
  )
}
export function getBGStyleOnHover(hover){
  
    if(hover === 'about'){
      //f0483c
      document.getElementById("favicon").href = "/abouticon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
      document.body.style = "background-color: #f0483c"
      return {backgroundColor: "#f0483c"}
    }
    else if(hover === 'code'){
      document.getElementById("favicon").href = "/codicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
      document.body.style = "background-color: #a04eed"
      return {backgroundColor:"#a04eed"}
    }
    else if(hover === 'design'){
      document.getElementById("favicon").href = "/designicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
      document.body.style = "background-color: #3e7a4e"
      return {backgroundColor:"#3e7a4e"}
    }
    else if(hover === 'music'){
      // edd84e
      document.getElementById("favicon").href = "/musicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
      document.body.style = "background-color: #ed9b4e"
      return {backgroundColor:"#ed9b4e"}
    }
    else if(hover === 'contact'){
      document.getElementById("favicon").href = "/contacticon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
      document.body.style = "background-color: #edd84e"
      return {backgroundColor:"#edd84e"}
    }
    else if(hover === "linkedin"){
      document.getElementById("favicon").href = "/linkedicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
      document.body.style = "background-color: #2867B2"
        return {backgroundColor: "#2867B2"}
    }
    else if(hover === "resume"){
      document.getElementById("favicon").href = "/resumicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
      document.body.style = "background-color: #e57aeb"
        return {backgroundColor: "#e57aeb"}
    }
    else if(hover === "github"){
      document.getElementById("favicon").href = "/githubicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
      document.body.style = "background-color: #632936"
        return {backgroundColor: "#632936"}
    }
    else{
      document.getElementById("favicon").href = "/favicon.ico?v=" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
      document.body.style = "background-color: #86c4ba"
      return {backgroundColor: "#86c4ba"}
    }
}
ReactDOM.render(
  
  <React.StrictMode >
    <Router>

      <Switch>
          <Route path="/about">
              <About bgco="#f0483c"/>
          </Route>
          <Route path="/code">
              <Code bgco="#a04eed"/>
          </Route>
          <Route path="/design">
              <Design bgco="#3e7a4e"/>
          </Route>
          <Route path="/music">
              <Music bgco="#ed9b4e" />
          </Route>
          <Route path="/contact">
              <Contact bgco="#edd84e" />
          </Route>
          <Route path="/">
              <Wrapper />
          </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
