import React from 'react';
import { useSpring, animated } from 'react-spring'
import {getBGStyleOnHover} from './index.js'
import './card.css'
import {Link} from "react-router-dom";
import Popup from "reactjs-popup";

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

function CLink(props){
    return (
        <div 
        >
            <li>
                <Link className="link" to={props.name.toLowerCase()}
                    style={{textDecorationColor: getRawColor(getBGStyleOnHover(props.hover))}}
                    onMouseEnter={() => props.setHover(props.name.toLowerCase())}
                    onMouseLeave={() => props.setHover(null)}
                >
                    {props.name}
                </Link>
            </li>
        </div>
    )
}
function Icon(props){
    return (
    <a className="iconContainerLink" style={{textDecorationColor: getIconColor(props.hover)}} href={props.link} 
        onMouseEnter={() => props.setHover(props.name.toLowerCase())}
        onMouseLeave={() => props.setHover(null)}
        target="_blank"
        rel="noopener noreferrer"
    >
        <i class={props.class} ></i>
    <p className="iconText" >{props.name}</p>
    </a>
    )
}
function getIconColor(hover){
    if(hover === "linkedin"){
        return "#2867B2"
    }
    else if(hover === "resume"){
        return "#e57aeb"
    }
    else if(hover === "github"){
        return "#632936"
    }
    if(hover === null){
        return "#040F0F"
        
    }
}
// TODO: MAKE ICON COMPONENTS JUST LIKE ^^^^^ LINK COMPONENTS
// #5de362, #e57aeb, #632936
// some color OPTIONS: feel free to play with these and others
function getRawColor(BGColor){
    return BGColor.backgroundColor || "black";
  }
function Innards(p){
    return(
        <div className="innards">
            <h1>Jack Horan</h1>
            <hr/>
            <ul>
                    <CLink hover={p.hover} setHover={props => p.setHover(props)} name="About" />
                    <CLink hover={p.hover} setHover={props => p.setHover(props)} name="Code" />
                    <CLink hover={p.hover} setHover={props => p.setHover(props)} name="Design" />
                    <CLink hover={p.hover} setHover={props => p.setHover(props)} name="Music" />
                    <CLink hover={p.hover} setHover={props => p.setHover(props)} name="Contact" />
                    
                    
            </ul>
            <div className="bottomIcons">
                <Icon link="https://www.linkedin.com/in/jackmhoran/" class="fab fa-linkedin fa-3x" name="LinkedIn" hover={p.hover} setHover={props => p.setHover(props)} />
                <Icon link="https://github.com/jackmhoran/" class="fab fa-github-square fa-3x" name="GitHub" hover={p.hover} setHover={props => p.setHover(props)} />
                
            </div>
            
            
        </div>
    )
}
function Card(prpz) {
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 280, friction: 200 } }))


    return (
        <div>
            <animated.div
                className="card"
                onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                style={{ transform: props.xys.interpolate(trans) }} 
            >
                <Innards hover={prpz.hover} setHover={p => prpz.setHover(p)}/>
            </animated.div>
        </div>
    )
  }



export default Card
